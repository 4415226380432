import {_delete, _get, _post, _put} from "@api/services/httpService";

const URI = "/user-clubs";
export const USER_ACCESS_PROFILE_URL = '/user-access-profiles';

export const getUserAccessProfiles = (itemsPerPage, page, search = '', id) =>
  _get(
    USER_ACCESS_PROFILE_URL +
    '?club.id=' + id +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + '&userType=user_club'
  )
;

export const getUser = (idUser) => _get(URI + '/' + idUser);

export const putUser = (idUser, data) => _put(URI + '/' + idUser, data);

export const getAll = (clubId) => _get(URI + '?clubs.id=' + clubId);

export const deleteUser = (idUser) => _delete(URI + '/' + idUser);

export const addUserClub = (data) => _post(URI, data);
