import {_get, _post, _put, _delete} from "@api/services/httpService";
import {URL} from "./index";

export const getClubClients = (idClub, itemsPerPage, page, search) =>
  _get(
    URL +
    '?club.id=' + idClub +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + '&search=' + search)
;

export const getUsersClub = (itemsPerPage, page, search) =>
    _get(
        'user-clubs' +
        '?itemsPerPage=' + itemsPerPage +
        '&page=' + page + '&search=' + search)
;

export const getRecentClubClients = (page) =>
  _get(
    URL +
    '?club.id=' + clubId +
    '&itemsPerPage=' + 3 +
    '&order[createdAt]=desc' +
    '&page=' + page
  )
;

export const getClubFamilyGroups = (idClub, itemsPerPage, page, search) =>
  _get(
    URL +
    '?exists[familyGroup]=false' +
    '&club.id=' + idClub +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + '&search=' + search)
;

export const postClubClient = (customClient) => _post('clubs/clients', customClient);

export const putClubClient = (customClient) => _put('clubs/clients/' + customClient.id, customClient);

export const deleteClubClient = (id) => _delete('clubs/clients/' + id);

export const getClubClient = (id) => _get('clubs/clients/' + id);

export const getClientFromUserClient = (userClientId, idClub) =>
  _get(URL +
    '?club.id=' + idClub +
    '&user.id=' + userClientId
  )
;

export const putUserClub = (userClub) => _put('user-clubs/' + userClub.id, userClub);
