import { hydrate } from "@/utils/form";

export default class User {
  accessProfiles = []
  avatar = null
  birthDate = null
  clubs = []
  createdAt = null
  description = null
  email = null
  emailNotificationEnabled = null
  enabled = true
  firstName = null
  id = null
  lastName = null
  newsReadAt = null
  phoneNumber = null
  roles = []
  updatedAt = null
  username = null

  constructor (object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }
}
